import { useState, useEffect } from "react";
import { Card } from "@tremor/react";
//import { apiPost } from "../../../data/apidata";
import "./../HeartbeatMonitor.css";
import { useUserSettings } from "../../../../components/UserSettingsProvider/UserSettingsProvider";
import { columns_template_heartbeat_monitoring_calls } from "../../../../components/AQDataTable/columns_templates";
import { getOnceValue } from "../../../../data/firebase";
import { use } from "react";

export default function DeviceCheckList() {

    const { userSettings, updateSettings } = useUserSettings();
    const [site, setSite] = useState(null);
    const [config, setConfig] = useState(null);
    const [liveDevices, setLiveDevices] = useState([]);
    const [heartBeat, setHeartBeat] = useState([]);
            
    useEffect(() => {
        if (!userSettings) return;
        setSite({...userSettings.site});
    }, [userSettings])

    useEffect(() => {
        if (site===null) return;
        const { code } = site;
        getOnceValue(code + "config", (d) => {
            console.log(d);
            setConfig("config", d);
        },
            () => { })
    }, [site])
    
    useEffect(() => {
        if (config === null) return;
        let tempDevices = config.map((d) => {

            return {
                id: d.id,
                name: d.name,
                status: "Offline",
                lastHeartBeat: "Never",
                lastCall: "Never"
            };

        }); 

        setLiveDevices(tempDevices);
    },[config, heartBeat])



/*
    const [unitCall, setUnitCall] = useState([]);
    const [unitConfig, setUnitConfig] = useState([]);
    const [masterConfig, setMasterConfig] = useState([]);
    const [mappedValues, setMappedValues] = useState([]);
*/

/*
    function getDBCode() {
        try {
            return userSettings.site.id;
        }
        catch (e) { return "" }
    }


    function getNotifications() {
        const siteID = getDBCode();
        if (firstLoad === true) setFirstLoad(false);

        if (siteID === "") return;

        const { code } = userSettings.site;

        getOnceValue(code + "heartBeat", (d) => {
            setUnitCall(d)
        },
            () => { })
        getOnceValue(code + "config", (d) => {
            setUnitConfig(d.filter(df => (df !== null)))
        },
            () => { })
    }

*/

    return (
        <div>
            <h4>Device Activity</h4>
            <p>In configuration</p>
            <br />

            <Card>
                <div className="row">
                    <div className="col-md-6">
                        <DevicesInChecklist deviceList={liveDevices} />
                    </div>
                    <div className="col-md-6">
                        <DevicesNotInChecklist deviceList={liveDevices} />
                    </div>
                </div>
            </Card>
        </div>
    )
};


const DevicesInChecklist=({deviceList})=>{

    return(
        <div>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Device</th>
                        <th>Status</th>
                        <th>Last Heartbeat</th>
                        <th>Last Call</th>
                    </tr>
                </thead>
                <tbody>
                    {deviceList.map((d) => {
                        return (
                            <tr key={d.id}>
                                <td>{d.name}</td>
                                <td>{d.status}</td>
                                <td>{d.lastHeartBeat}</td>
                                <td>{d.lastCall}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

        </div>
    );
}

const DevicesNotInChecklist=({deviceList})=>{

    return(
        <div>

        </div>
    );
}