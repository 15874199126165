import React, { useEffect, useRef } from "react";
import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";

const VisTimeline = () => {
  const timelineRef = useRef(null);

  useEffect(() => {
    if (!timelineRef.current) return;

    // Define items
    const items = new DataSet([
      { id: 1, content: "Event 1", start: "2024-02-01" },
      { id: 2, content: "Event 2", start: "2024-02-05" },
      { id: 3, content: "Event 3", start: "2024-02-10", end: "2024-02-12" },
      { id: 4, content: "Event 4", start: "2024-02-15" },
    ]);

    // Timeline options
    const options = {
      start: "2024-01-25",
      end: "2024-02-20",
      editable: false,
      margin: { item: 20 },
      orientation: { axis: "top" },
    };

    // Initialize timeline
    const timeline = new Timeline(timelineRef.current, items, options);

    return () => timeline.destroy(); // Cleanup on unmount
  }, []);

  return (
    <div>
      <h2>Vis.js Timeline in React</h2>
      <div ref={timelineRef} style={{ height: "300px" }}></div>
    </div>
  );
};

export default VisTimeline;
