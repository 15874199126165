import { useState, useEffect } from "react";
import "./HeartbeatMonitor.css";
import { useUserSettings } from "../../../components/UserSettingsProvider/UserSettingsProvider";
import { getOnceValue, getOnValue } from "../../../data/firebase";
import { apiGet, apiPost } from "../../../data/apidata";
import { createMRTColumnHelper } from 'material-react-table';
import { GetHeartbeatIcons } from "../../../components/GetImage";
import { displayDateTimeDisplayFromUTC, displayDateTimeFromUTCExport } from '../../../functions/dataHandler';
import HeartbeatTable from "../../../components/AQDataTable/HeartbeatTable";

const columnHelper = createMRTColumnHelper();

export default function HeartbeatMonitor() {

  const { userSettings, updateSettings } = useUserSettings();
  const [firstLoad, setFirstLoad] = useState(true);
  const [unitCall, setUnitCall] = useState([]);
  const [unitConfig, setUnitConfig] = useState([]);
  const [masterConfig, setMasterConfig] = useState([]);

  const [dbConfig, setDbConfig] = useState([]);


  function getDBCode() {
    try {
      return userSettings.site.id;
    }
    catch (e) { return "" }
  }

  function getNotifications() {
    /*
    if ((userSettings)&&(userSettings.site)&&(userSettings.site.id)){
    apiPost("/api/messages/settings/heartbeats",{ siteID: userSettings.site.id }, 
    (d) => {

        d = Object.keys(d).map((key) => {
            const heartbeatList = d[key];
            const lastHeartbeat = heartbeatList[0];
            const { newStatus } = checkDate(lastHeartbeat.heartbeatDateTime);


            return { 
                unitId: key, 
                fullDate: lastHeartbeat.heartbeatDateTime,
                lastTime: lastHeartbeat.heartbeatDateTime,
                unitName: lastHeartbeat.deviceName,
                unitStatus: newStatus,
                unitZone: lastHeartbeat.deviceZone,
                heartbeats: heartbeatList.map((hb) => hb.heartbeatDateTime),
                ...d[key] 
            }
            
        });

        setDbConfig(d);
    }, (e) => { console.log(e) });   
}
    */
    const siteID = getDBCode();
    if (firstLoad === true) setFirstLoad(false);

    if (siteID === "") return;

    const { code } = userSettings.site;

    getOnceValue(code + "heartBeat", (d) => {
      setUnitCall(d)
    },
      () => { })
    getOnceValue(code + "config", (d) => {
      setUnitConfig(d.filter(df => (df !== null)))
    },
      () => { })
  }

  useEffect(() => { getNotifications(); }, [firstLoad === true]);

  function getTimeAndDifference(value) {
    var newTime = "";
    const [date, timeWithMicroseconds] = value.split('T');
    const [time] = timeWithMicroseconds.split('.');
    const [year, month, day] = date.split('-');

    // Combine into desired format DD/MM/YYYY HH:MM:SS
    newTime = `${day}/${month}/${year} ${time}`;

    // Convert to Date object
    const dateTime = new Date(`${date}T${time}`);
    const now = new Date();
    // Calculate the time difference in hours
    const timeDifferenceInHours = (now - dateTime) / (1000 * 60 * 60);

    return { newTime, timeDifferenceInHours };
  }



  function checkDate(value) {
    const { newTime, timeDifferenceInHours } = getTimeAndDifference(value);
    // Determine if the unit is ACTIVE or INACTIVE
    let newStatus = "";

    if (timeDifferenceInHours < 24) {
      newStatus = 'Active';
    }
    else if (timeDifferenceInHours >= 24 && timeDifferenceInHours < 120) {
      newStatus = 'Perform Service Test';
    }
    else {
      newStatus = 'Inactive';
    }
    return { newStatus, newTime };
  }

  function getDateValues(value) {
    if (value === undefined) return { newStatus: "", newTime: "", vTime: "" };
    const valueTime = (value.time) ? value.time : value;
    const { newStatus, newTime } = checkDate(valueTime);
    return { newStatus, newTime, vTime: valueTime };
  }

  useEffect(() => {
    const configs = unitConfig.map((item) => {
      const { newStatus, newTime, vTime } = getDateValues(unitCall[item.unitId]);
      return { unitStatus: newStatus, unitId: item.unitId, unitName: item.unitName, unitZone: item.unitZone, lastTime: newTime, fullDate: vTime }
    });
    setMasterConfig(configs.filter(item => item.unitStatus !== ""));

  }, [unitCall, unitConfig]);

  console.log("masterConfig", masterConfig);

  return (
    <div>
      <h4>Device Connectivity Monitoring</h4>
      <br />
      <div>{
        //lastUpdated.toString()
      }</div>
      {//}            <MonitorTestCalls siteCode={"sensetest"} />
      }
      <HeartbeatTable
        data={masterConfig}
        columns={columns_template_heartbeat_monitoring_calls}
        settingsType={"Heartbeat_Monitor"}
        onUpdateRefreshPath={""}
      />
      {/*
            <AQDataTable
                data={masterConfig}
                columns={columns_template_heartbeat_monitoring_calls}
                settingsType={"Heartbeat_Monitor"}
                onUpdateRefreshPath={""}
            />
*/}
    </div>
  )
};




export const columns_template_heartbeat_monitoring_calls = [

  columnHelper.accessor('unitStatus', {
    header: 'Status',
    size: 40,
    filterVariant: 'multi-select',
    filterSelectOptions: ['Active', 'Perform Service Test', 'Inactive'],
    Cell: ({ renderedCellValue, row }) => {
      if (renderedCellValue === "Active")
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'inline-block' }}><GetHeartbeatIcons imgString={'YES'} /></span>
            <span style={{ display: 'inline-block', marginLeft: '5px' }}>Active</span>
          </div>);
      else if (renderedCellValue === "Inactive")
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'inline-block' }}><GetHeartbeatIcons imgString={'NO'} /></span>
            <span style={{ display: 'inline-block', marginLeft: '5px' }}>Inactive</span>
          </div>);
      else if (renderedCellValue === "Perform Service Test")
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ display: 'inline-block' }}><GetHeartbeatIcons imgString={'SERVICE'} /></span>
            <span style={{ display: 'inline-block', marginLeft: '5px' }}>Perform Service Test</span>
          </div>);

      return (<div>NULL</div>);
    },
  }),

  columnHelper.accessor('unitId', {
    header: 'Unit ID',
    size: 40,
  }),

  columnHelper.accessor('unitName', {
    header: 'Room',
    size: 40,
  }),

  columnHelper.accessor('unitZone', {
    header: 'Zone',
    size: 40,
  }),

  columnHelper.accessor('fullDate', {
    header: 'Last reported',
    size: 40,
    Cell: ({ renderedCellValue, row }) => (
      <div>{displayDateTimeDisplayFromUTC(renderedCellValue)}</div>
    ),

  }),
  /*
      columnHelper.accessor('fullDate', {
          header: 'Last reported',
          size: 40,
          Cell: ({ renderedCellValue, row }) => (
              <BatteryTimeline batteryData={[]} heartbeats={row.original.heartbeats} />
          ),
    
        }),
    */
];



const MonitorTestCalls = ({ siteCode }) => {

  const [data, setData] = useState([]);

  //getdata from firebase function
  function getData() {
    getOnValue("sensetest_site/calls/10032025", (callsList) => {

      console.log("callslist", callsList);
      try {
        setData(Object.values(callsList).filter((item) => (item.callType === "Visit")));
      }
      catch (e) {
        console.log(e);
        setData([]);
      }
      setData(Object.values(callsList).filter((item) => (item.callType === "Visit")));

    }, (e) => { console.log(e); });
  }

  useEffect(() => {
    if (siteCode) getData();
  }, [siteCode]);


  return (
    <div className="monitor_test_calls_card">
      <h4>Today's device test Calls</h4>
      <small>For devices that aren't in regular use - it is recommended to perform a test every 24 hours.</small>
      <div className="monitor_test_calls_container">
        {(data) && data.map((item, index) => (
          <div className="test_call_card_container" key={index}>
            <div>{item.unitId}</div>
            <div>{item.room} ({item.zone})</div>
            <div>{displayDateTimeFromUTCExport(item.start_date_time)}</div>
          </div>
        ))}
      </div>


    </div>
  )
  //process data function

}
