import { SystemUpdateAltSharp as Download, Backup, Add } from '@mui/icons-material';
import { useUserSettings } from '../UserSettingsProvider/UserSettingsProvider';
import { apiPost } from '../../data/apidata';
import { Step1, Step2 } from '../SiteManagement/EditSiteConfig';
const { useState, useEffect } = require("react")


export const EditCallConfig = ({ onClose }) => {
    const [config, setConfig] = useState({})
    const [currStep, setCurrStep] = useState(1)
    const [site_id, setSiteID] = useState("")
    const [page_error, setPage_error] = useState('');
    const { userSettings, updateSettings } = useUserSettings();

    useEffect(() => {

        setSiteID(userSettings.site.id)
        if (userSettings.site.id != null) {
            apiPost(
                'api/messages//site/management/GetSiteConfig',
                { site_id: userSettings.site.id },
                d => {
                    console.log(d['message']['data'][0][0])
                    let data = d['message']['data'][0][0];
                    if (data.devices === null) data['devices'] = [{
                        device_name: '',
                        unit_code: '',
                        unit_zone: '',
                        device_type: '',
                        isSelected: false,
                        error: ''
                    }]
                    else
                        data['devices'] = data.devices.map(item => {
                            return ({
                                device_name: item.device_name,
                                unit_code: item.device_code,
                                unit_zone: item.device_zone,
                                device_type: item.device_type,
                                isSelected: false,
                                error: ''

                            })
                        })


                    //Get everything room to Room Unit
                    let dt =
                        data.device_types.filter(item =>
                            item.name.toLowerCase() !== 'communal unit' &&
                            item.name.toLowerCase() !== 'ensuite unit' &&
                            item.name.toLowerCase() !== 'bedroom unit'
                        )
                    data['device_types'] = dt;
                    data['old_zones'] = data.zones
                    console.log('site config', data)
                    setConfig(data)

                },
                e => {
                    console.log(e)
                }
            )
        }

    }, [])

    return (
        <div className='new_user_page_container'><h1 style={{ marginBottom: '0px' }}>Edit site config</h1>
            <span className='formFieldError'>{page_error}</span>
            <br />{config != null ?
                (
                    currStep === 1 ?
                        <Step1 currStep={currStep} setCurrStep={setCurrStep}
                            config={config} setConfig={setConfig} onClose={onClose}
                            page_error={page_error} setPage_error={setPage_error} />

                        :
                        <Step2 currStep={currStep} setCurrStep={setCurrStep}
                            config={config} onClose={onClose}
                            page_error={page_error} setPage_error={setPage_error} />

                )
                :
                <div className='aqForm'>
                    <div className='aq_fields' >
                        <div>
                            Loading
                        </div>
                        <div className=' bottom_field'  >

                            <div className='validate_close_div' >
                                <button className='close_buton btn_size' onClick={onClose}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}
