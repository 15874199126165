import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  Box,
  Button,
} from '@mui/material';
import AQModal from '../AQModal/AQModal';
import { useState } from 'react';
import ExportHandler from '../../Pages/Exports/export_handle';
import { useSnackbar } from 'notistack';

const _tableHeaderFontSize = "12.5px";
const _tableBodyFontSize = "12.5px";

const HeartbeatTable = ({ data, columns, settingsType, onUpdateRefreshPath, dataRefresh, siteID, options }) => {

  const [rowCount, setRowCount] = useState(0);

  const [modalElement, setModalElement] = useState(null);
  const [modalHeight, setModalHeight] = useState(null);
  const { enqueueSnackbar } = useSnackbar();


  function cleanData(data, tableType) {
    return data;
  }

  const defaultSettings = {
    ...MasterTableSettings,
    renderTopToolbarCustomActions: ({ table }) => (<ExportButton tableData={table} />),
  }


  const ExportButton = ({ tableData }) => {

    const [exportData, setExportData] = useState(null);

    return (
      <>
        {
          (exportData !== null) &&
          <ExportHandler title={"data"} data={exportData} onClose={() => setExportData(null)} masterData={data} show={(exportData !== null)} processor={settingsType} />
        }
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          <Button
            onClick={() => {
         
              const rows = ((tableData.getIsAllRowsSelected()) ? tableData.getSelectedRowModel().rows : tableData.getSelectedRowModel().rows);
              const rowData = rows.map((row) => row.original);
             
              if ((rows !== null) && (rows.length > 0)) {
                setExportData(cleanData(rowData));
              }
              else {
                setExportData(cleanData(data));
              }
            }}
            disabled={tableData.getSelectedRowModel().rows.length === 0}
            startIcon={<FileDownloadIcon />}
          ></Button>
        </Box>
      </>
    );
  }

  
  
  const table = useMaterialReactTable({ columns , data, initialState: { pagination: { pageSize: 50, pageIndex: 0 } }, ...defaultSettings });



  function closeModal() {
    setModalHeight(null)
    setModalElement(null);
    if (onUpdateRefreshPath !== undefined) {
      if (dataRefresh) dataRefresh();
    }
    else {
      if (dataRefresh) dataRefresh();
    }
  }

  
  return (
    <>
      {
        (modalElement !== null)
        &&
        <AQModal show={true} onClose={closeModal} height={modalHeight}>
          {modalElement}
        </AQModal>
      }
      <MaterialReactTable table={table} />
    </>
  );

};


export const MasterTableSettings = {
  selectAllMode: "all",
  enableRowActions: true,
  enableRowSelection: true,
  columnFilterDisplayMode: 'popover',
  positionToolbarAlertBanner: 'bottom',
  positionActionsColumn: 'last',
  enableFullScreenToggle: false,
  muiTableHeadCellProps: {
    //simple styling with the `sx` prop, works just like a style prop in this example
    sx: {
      fontWeight: 'bold',
      fontSize: _tableHeaderFontSize,
      color: "gray"
    },
  },

  muiTableBodyRowProps: ({ row }) => ({
    //conditionally style selected rows
    sx: {
      fontWeight: row.getIsSelected() ? 'bold' : 'normal',
      fontSize: _tableBodyFontSize,
      color: "gray"
    },
  }),
  muiTableBodyCellProps: ({ column }) => ({
    //conditionally style pinned columns
    sx: {
      fontWeight: column.getIsPinned() ? 'bold' : 'normal',
      fontSize: _tableBodyFontSize,
      FontFace: "sans-serif",
      border: 0,
      color: "gray"
    },
  }),
  displayColumnDefOptions: {
    'mrt-row-actions': {
      header: '', //change header text
      //size: 300, //make actions column wider
    },
  },
  muiTableBodyProps: {
    /*
    ADC to go live
    sx: {
      //stripe the rows, make odd rows a darker color
      '& tr:nth-of-type(odd) > td': {
        backgroundColor: '#f5f5f5',
      },
    },
    */
  },
  muiBottomToolbarProps: {

  },

  initialState: {
    pagination: {
      pageSize: 50,
      pageIndex: 0, // Optional: you can set the initial page index if needed
    },
  },


  muiPaginationProps: {
    // defaultPage:{

    // }


    rowsPerPageOptions: [10, 25, 50, 100],
    // showFirstButton: true,
    // showLastButton: true,
    // color: 'primary',
    // shape: 'rounded',
    showRowsPerPage: true,
    // count: {global_rowCount}
    // variant: 'outlined',

    sx: {
      fontSize: '15px',
      button: {
        fontWeight: 'bold',
        fontSize: "15px",
      },
      backgroundColor: '#f5f5f5',
    },

  },
};

 

export function utcDateToYYYYMMDD(utcDate) {
  const date = new Date(utcDate);

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // 24-hour format
    timeZone: 'UTC', // Adjust if you want to convert to local time
  };

  const formattedDateParts = new Intl.DateTimeFormat('en-GB', options)
    .formatToParts(date)
    .reduce((acc, part) => {
      if (part.type !== 'literal') {
        acc[part.type] = part.value;
      }
      return acc;
    }, {});

  return`${formattedDateParts.year}-${formattedDateParts.month}-${formattedDateParts.day}`;

}

  export function utcDateToHHMMSS(utcDate) {
    // Ensure utcDate is a valid Date object
    utcDate = utcDate.toString();
    if (typeof utcDate === undefined) return '';
    if (typeof utcDate !== 'string') return '';

    let dattt = utcDate
    const options = {
      timeZone: "Europe/London",
/*      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
 */     hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Use 24-hour format
    };

    var tmp = utcDate
    utcDate = new Date(utcDate);
    
    if (!isValidDate(utcDate)) {
        console.log("Invalid date:: ", utcDate)
        return '';
    }

    if (!(utcDate instanceof Date) || isNaN(utcDate)) {
      // utcDate = "Tue Jul 23 2024 12:24:27 GMT+0100"
      
      console.log("Invalid Data", tmp)
      //throw new Error('Invalid Date object');
    }
    else{
      // console.log(tmp)
    }
     
    // Create a formatter with the UK timezone
    const formatter = new Intl.DateTimeFormat('en-GB', options);

    // Format the UTC date to the UK timezone
    const ukDateStr = formatter.format(utcDate);
    return ukDateStr;
  
}

function isValidDate(dt) {
  dt = new Date(dt);
  if (dt.toString() === "Invalid Date") return false;
  if (!(dt instanceof Date) || isNaN(dt)) {
    return false;
  }
  else {
    return true;
  }
}


export default HeartbeatTable;