import React, { useEffect, useState, useRef } from 'react';
import './NourishMonitor';
import { apiPost } from '../../data/apidata';
import { useSnackbar } from 'notistack';
import DELETE_ICON from "../../images/settings/delete.png"

import { groupBy } from "../../functions/objects_arrays";
// import DELETE_ICON from '../../images/settings/delete.png';
// import { utcDateToHHMMSS } from "../../components/AQDataTable/columns_templates";
import { utcDateToYYYYMMDD } from "../../components/AQDataTable/columns_templates.js";
import AQToolTip from '../../components/ToolTip/ToolTip';

export const NourishResidentEdit = ({ userData, siteID, dataRefresh, options, onClose }) => {
    //console.log(userData);
    const { enqueueSnackbar } = useSnackbar();


    const [passwordError, setPasswordError] = useState("");
    const [error, setError] = useState(false);
    const [errorNHS, setErrorNHS] = useState(false);
    const [errorDOB, setErrorDOB] = useState(false);
    const [genderList, setGenderList] = useState([])

    useEffect(() => {
        setGenderList(["male", "female", "other"]);
    }, [])

    // Handle input changes
    const handleChange = (e) => {
        // const { name, value } = e.target;
        // setFormData((prevData) => ({
        // ...prevData,
        // [name]: value,
        // }));
    };

    function siteChange(val) {
        // console.log("siteChange", val);
        // setFormData((prevData) => ({
        // ...prevData,
        // ["siteID"]: val,
        // }));
    };


    function cleanError(message) {
        // console.log({message});
        // if (message.indexOf("Object didn't pass validation for format email:")>-1) return `Invalid email address format.`
        // return message;
    }

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // You can perform actions with formData here, such as sending it to an API
        //console.log('Form data submitted:', formData);

        enqueueSnackbar("User Updated", { variant: "success" });
        if (onClose) onClose();
        return;
        
        /*
        apiPost(
            "",
            {
            },
            (d) => {
                enqueueSnackbar("User Updated", { variant: "success" });
                if (onClose) onClose();
            },
            (e) => {
            }
        )
        */
    };

    useEffect(() => {

        let nhs = userData.nhsNumber;
        let error = false;
        if (nhs === undefined || nhs === null) error = true;
        else if ((nhs.length === 10 && !isNaN(nhs) && nhs.trim() === nhs) === false) error = true;


        //setErrorNHS(error)
    }, [userData])

    useEffect(() => {
        if (errorNHS === false && errorDOB === false) setError(true);
        else setError(false);
    })

    return (
        <div className='new_user_page_container'>
            <h1>Edit Resident</h1>
            <div className='aqForm' >
                {/* {(error !== "") && <><small className='formFieldError'>{error}</small><br /></>} */}

                <div className='aq_fields' >

                    <label>
                        Title:
                        <NourishInput
                            type="text"
                            name="title"
                            value={userData.title}
                            onChange={handleChange}
                        />
                    </label>
                    <br />

                    <label>
                        Name:
                        <NourishInput
                            type="text"
                            name="firstName"
                            value={userData.firstName}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Surname:
                        <NourishInput
                            type="text"
                            name="surname"
                            value={userData.surname}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        NHS number:
                        {errorNHS ?
                            <AQToolTip tooltip={<div className="tooltipStyle">Please refer to nourish website to fix this</div>}>
                                <div className='errorMessage'>invalid NHS number</div>
                            </AQToolTip>
                            : <></>}
                        <NourishInput
                            type="text"
                            name="nhs"
                            value={userData.nhsNumber}
                            onChange={handleChange}
                        />
                    </label>
                    <br />
                    <label>
                        Date of birth:
                        <NourishInput
                            type="text"
                            name="dob"
                            value={utcDateToYYYYMMDD(userData.dob)}
                            onChange={handleChange}
                        />
                    </label>
                    <br />

                    <label>
                        Gender:
                        <NourishInput
                            type="text"
                            name="gender"
                            value={userData.gender}
                        />
                    </label>
                    <br />
                    {error ?
                        <div>
                            <RoomAssigment user={userData} siteID={siteID} options={options} />
                        </div>
                        : <div className='errorMessage'>The above issues must be fixed before assigning rooms</div>
                    }

                </div>
                <div className='submit_fields'>
                    <div className='validate_close_div'>
                        <button className='validate_button' onClick={handleSubmit}>Update user</button>
                        <button className='close_buton' onClick={onClose}>Close</button>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default NourishResidentEdit;

const RoomAssigment = ({ user, siteID, options }) => {
    user.dob = utcDateToYYYYMMDD(user.dob);


    const [zoneList, setZoneList] = useState([]);
    const [load, setLoad] = useState(null);
    // const [loadZone, setLoadZone] = useState(true);

    const [selectedUnits, setSelectedUnits] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    // const [newZone, setNewZone] = useState(null);


    //Get zones
    useEffect(() => {
        if (options === undefined) return;
        if (options.length === 0) return;
        const tmp = Object.keys(groupBy(options, "zone"));
        setZoneList(tmp)
        setLoad(true)
    }, [options])


    useEffect(() => {
        getRooms();
    }, [load])

    function getRooms() {
        if (load === true) {
            apiPost("api/messages/residents/resident/getResidentRoomUnits",
                {
                    siteID: siteID,
                    dob: user.dob,
                    nhsNumber: user.nhsNumber,
                },
                (d) => {
                    let rr = d.message.data[0][0].map(unitRecord => {
                   
                        return ({
                            "unitCode": unitRecord.unitCode,
                            "value": options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].value,
                            "zone": options.filter(optionRecord => (optionRecord.unitCode === unitRecord.unitCode))[0].zone,
                        })
                    })
                    
                    //if (rr.length > 0)
                        
                    //setSelectedZone(rr[0].zone)

                    setSelectedUnits(rr)
                    setLoad(false)
                },
                (e) => {
                    console.log("Room units error", e)
                })
        }
    }
    function handleZoneSelection(zone) {
        const selectedOption = options.find(opt => opt.zone === zone);
        if (selectedOption) {
            
            if (selectedOption.zone !== selectedZone) {
                setSelectedZone(selectedOption.zone)
                //clearAll()
                setLoad(true)
            }
        }
    }
    function clearAll() {
        
        selectedUnits.map(item => {
            removeRoom(item.unitCode)
        })
        setLoad(true)
    }

    function addRoom(id) {
        apiPost("api/messages/residents/resident/addunit",
            { ...user, ...{ "unitCode": id }, ...{ "siteID": siteID } },
            (d) => {
                setLoad(true)
            }, (e) => {
                console.log(e)
                setLoad(true)
            }
        )
    }
    function removeRoom(id) {

        console.log('tag data', { ...user, ...{ "unitCode": id }, ...{ "siteID": siteID } })

        apiPost("api/messages/residents/resident/removeunit",
            { ...user, ...{ "siteID": siteID, "unitCode": id } },
            (d) => {
                setLoad(true)
            }
            , (e) => {
                console.log(e)
                setLoad(true)
            })
    }



    if (options === undefined) return ("No room units config")

    return (
        <div className='aq_room_div'>
            <h5>Assign Rooms</h5>
            <div>
                <div >
                    <label>
                        Select zone:
                        <ZoneSelection selectedZone={selectedZone} zoneList={zoneList} handleZoneSelection={handleZoneSelection} />
                    </label>
                    <br />

                    Assigned bedrooms
                    <div className="flex-row">
                        <div style={{ display: "flex", flexDirection: 'column' }}>
                            <div style={{ marginBottom: "5px" }}>
                                {selectedUnits != null ? selectedUnits.map((d) => (
                                    d.unitCode != null ?
                                        <div className="tag-item">
                                            <span className="text" >{d.value}</span>
                                            {/* <img src={DELETE_ICON} onClick={()=>removeSite(d.siteID)} className="close"/>  */}
                                            <span onClick={() => removeRoom(d.unitCode)} className="close">&#10006;</span>
                                        </div>
                                        : null
                                )) : null}
                            </div>
                            {
                                selectedUnits != null ?
                                    selectedUnits.length > 1 ?

                                        <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                            <button className='clear_button' onClick={clearAll}>
                                                {/* Clear <span>&#10006;</span> */}
                                                Clear <img src={DELETE_ICON} />
                                            </button>

                                        </div>
                                        : null
                                    : null
                            }
                            <SelectableUnits options={options} selectedZone={selectedZone} selectedUnits={selectedUnits} addRoom={addRoom} />
                        </div>

                    </div>



                </div>
            </div>

        </div>

    );
}
const ZoneSelection = ({ selectedZone, zoneList, handleZoneSelection }) => {
    return (
        <div>
            <select
                className='selected_site aqselectform'
                value={selectedZone}
                onChange={
                    (e) => {
                        handleZoneSelection(e.target.value);
                    }
                }
            >
                <option selected={selectedZone === null}></option>
                {zoneList.map(opt =>
                    <option value={opt} selected={selectedZone === opt}>{opt}</option>

                )}
            </select>
        </div>
    )
}
const SelectableUnits = ({ options, selectedZone, addRoom, selectedUnits }) => {

    let selectedUnitcode = null
    function setSelectedUnitCode(e) {
        // () => { selectedUnitcode = o.unitCode }
    }

    if (selectedZone == null) return
    if (options == null) return

    let res = [];
    
    options.find(item => {
        if (item.zone === selectedZone) {

            if (!selectedUnits == null) {
            
                var ff = selectedUnits.find(un => un.unitCode === item.unitCode)
                
                if (!ff)
                    res.push(item)
            }
            else {
                res.push(item)
            }
        }
    }
    )
    if (res.length > 0) {

    }
 
    return (
        <div>
            <input
                className="tag_input"
                type="text"
                id="autocomplete-input"
                list="suggestions"
                placeholder="Select a bedroom to add..."
                onChange={(e, key) => {
                    // if (selectedUnitcode != null) {
                    const selectedOption = res.find(opt => opt.value === e.target.value);

                    if (selectedOption) {
                        //if (onAdd) onAdd(selectedOption);
                        addRoom(selectedOption.unitCode);
                        e.target.value = '';
                        // selectedUnitcode = null
                    }
                    // }
                }}
            />
            <datalist id="suggestions">
                {res.map(o => <option key={o.unitCode} value={o.value}>{o.value}</option>)}
            </datalist>
        </div>
    )

}




const NourishInput = ({ id, name, type, value, placeholder, onChange, }) => {
    return (
        <input
            readOnly='true'
            className="nourishReadonly shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id={id}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
        />
    );
}


