import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const MovePieChart = ({data}) => {


    if (data === undefined) return <></>;  
    if (data === null) return <></>;
     

  return (
    <div style={{ width: "300px", height: "300px" }}>
      <Pie data={data} />
    </div>
  );
};

export default MovePieChart;
