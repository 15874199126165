import React, { useEffect, useState } from 'react';
import { useUserSettings } from '../components/UserSettingsProvider/UserSettingsProvider';
import { Tabs, Tab } from '@mui/material';
import MoveResidentData from './MoveResidentData';
import MoveCarerData from './MoveCarerData';
import AQDateRangePicker from '../components/Chart/DateRangePicker/DateRangePicker';

const Move=({move})=>{
    
    const { userSettings, updateSettings } = useUserSettings();
    const site_guid = userSettings.move.key;
    const [selectedTab, setSelectedTab] = useState(0);



    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    //'01861232-864d-460e-2931-bfc95ddd4753'; //iiwari
    





    const LiveView = () => {
        return(
            <iframe
                    id='iiwariframe'
                    src={`https://app.iiwari.cloud/arquella/map/${(move.key)?move.key:""}?token=${move.userToken}&touch=1`} 
//                    src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                    //sandbox="allow-top-navigation"
                    style={{
                        marginLeft: "2.5%",
                        marginTop: "2.5%",
                        alignSelf: "center",
                        height: "80vh",
                        width: "1200px",
                        marginRight:"10px", 
                        marginTop:"10px",
                    }}
                    >
                </iframe>
        );
    }



    if (move === null)  return <div>Site code is not configured.  Please contact Arquella.</div>;

    return(
        <div 
        //className='grid-30-70-container'
        >
                
                <Tabs value={selectedTab} onChange={handleChange}>
                    <Tab label="Live" />
                    <Tab label="Rooms" />
                    <Tab label="Carers" />
                </Tabs>
                <br />
                {selectedTab === 0 && <LiveView />}
                {selectedTab === 1 && <MoveResidentData siteCode={move.key} />}
                {selectedTab === 2 && <MoveCarerData siteCode={move.key} />}
        </div>
    );





    return(
            <div className='grid-30-70-container'>
                

                <div width="100%">
                <iframe
                    id='iiwariframe'
                    src={"https://app.iiwari.cloud/arquella/map/018aa78c-eba2-d114-0710-c485b84d1476?token=c7G7EEDQV0HzNHDTgSgRD9awXx4zX5HrxpyUqKWr7coO3xoI9v3N5LpefXgHU10QaGovZhWAJgqAdysDBr&touch=1"} 

//                    src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                    //sandbox="allow-top-navigation"
                    style={{
                        marginLeft: "2.5%",
                        marginTop: "2.5%",
                        alignSelf: "center",
                        height: "80vh",
                        //margin: "3vh",
                        //width: "95%",
                        width: "1200px",
        //                    minHeight: "",
                        marginRight:"10px", 
                        marginTop:"10px",
                        //border:"none",
                    }}
                    >
                </iframe>



                <iframe
                    id='iiwariframe' 
                    src={"https://app.iiwari.cloud/arquella?site=" + site_guid + "&theme=iframe"}
                    //sandbox="allow-top-navigation"
                    style={{
                        marginLeft: "2.5%",
                        marginTop: "2.5%",
                        alignSelf: "center",
                        height: "80vh",
                        //margin: "3vh",
                        //width: "95%",
                        width: "1200px",
        //                    minHeight: "",
                        marginRight:"10px", 
                        marginTop:"10px",
                        //border:"none",
                    }}
                    >
                </iframe>
                {/*<LocationEventRunner></LocationEventRunner>*/}
                <div id="newtest"></div>
            </div>

            </div>

    );

}

export default Move;