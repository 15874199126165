import React, { useEffect, useState } from 'react';
import { createMRTColumnHelper } from 'material-react-table';
import { secondsToHHMMSS } from '../functions/dataHandler';
import MoveResidentBreakdownTable from '../components/AQDataTable/MoveResidentBreakdownTable';
import { utcDateToHHMMYYYYHHMM } from '../components/AQDataTable/columns_templates';
import MovePieChart from './MovePieChart';

const columnHelper = createMRTColumnHelper();

const MoveCarerDataBreakdown=({data})=>{
    

    const [chartData, setChartData] = useState(null);

    useEffect(()=>{
        if (data === undefined) return;
        if (data===null) return;
        if (data.records === undefined) return;

        //group by tag_name
        const groupedData = data.records.reduce((acc, element) => {
            const key = element.zone_name;
            if (!acc[key]) { acc[key] = []; }
            acc[key].push(element);
            return acc;
        }, {});


        const chartLabels = Object.keys(groupedData);
        const chartData = Object.values(groupedData).map((d)=>d.length);

        const cData = {
            labels: chartLabels,
            datasets: [
              {
                data: chartData, // Dummy values
                backgroundColor: [
                  "rgba(255, 99, 132, 0.6)",
                  "rgba(54, 162, 235, 0.6)",
                  "rgba(255, 206, 86, 0.6)",
                  "rgba(75, 192, 192, 0.6)",
                  "rgba(153, 102, 255, 0.6)",
                ],
                borderColor: [
                  "rgba(255, 99, 132, 1)",
                  "rgba(54, 162, 235, 1)",
                  "rgba(255, 206, 86, 1)",
                  "rgba(75, 192, 192, 1)",
                  "rgba(153, 102, 255, 1)",
                ],
                borderWidth: 1,
              },
            ],
          };
          
          setChartData(cData);

    },[data]);

    return(
        <div style={{padding: '10px', backgroundColor: '#f9f9f9' , width: '100%'}}
            //className='grid-30-70-container'
        >
            <h2>{data.tag_name} Breakdown</h2>

            <div style={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap' }}>
                <div style={{ width: '70%' }}>
                    <MoveResidentBreakdownTable
                        data={data.records}
                        columns={columns}
                        settingsType={"Heartbeat_Monitor"}
                        onUpdateRefreshPath={""}
                    />
                </div> 
                <div><MovePieChart title="Carers" data={chartData} /></div>
                
            </div>      


        </div>        
    );

}


 export const columns = [

    columnHelper.accessor('tag_name', { header: 'Carer', size: 40 }),
    columnHelper.accessor('time_beg', { header: 'Start', size: 40,
        Cell: ({ renderedCellValue, row }) => (
            <div>{utcDateToHHMMYYYYHHMM(renderedCellValue)}</div>
        ), }),
    
    columnHelper.accessor('time_end', { header: 'End', size: 40,
        Cell: ({ renderedCellValue, row }) => (
            <div>{utcDateToHHMMYYYYHHMM(renderedCellValue)}</div>
        ) }),
    columnHelper.accessor('duration', { header: 'Total Duration', size: 40,
        Cell: ({ renderedCellValue, row }) => (
            <div>{secondsToHHMMSS(renderedCellValue)}</div>
        ),
    }),
    columnHelper.accessor('zone_name', { header: 'Room', size: 40 }),
    


  ];

export default MoveCarerDataBreakdown;