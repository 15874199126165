import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const VerticalHistogram = ({data, keys}) => {
    if (keys.length<=0) return <></>;

  return (
    <BarChart
      series={[ { data: data } ]}
      height={290}
      xAxis={[{ data: keys, scaleType: 'band' }]}
      margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
    />
  );
}

export default VerticalHistogram;