import React, { useEffect, useState } from 'react';
import { useUserSettings } from '../components/UserSettingsProvider/UserSettingsProvider';
import { apiPost } from '../data/apidata';
import { createMRTColumnHelper } from 'material-react-table';
import MoveCarerTable from '../components/AQDataTable/MoveCarerTable';
import { secondsToHHMMSS } from '../functions/dataHandler';
import AQDateRangePicker from '../components/Chart/DateRangePicker/DateRangePicker';

const columnHelper = createMRTColumnHelper();

const MoveCarerData=({siteCode})=>{

    
    const [data, setData] = useState([]);

      const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0); // Set to the start of the day
      
        const today = new Date();
        today.setHours(23, 59, 59, 999); 
    
        const [value, setValue] = useState({
            from: sevenDaysAgo.toISOString(),
            to: today.toISOString(),
        });
    
        function updateLocalValues(){
            try {
                localStorage.setItem("startDate", value.from);
                localStorage.setItem("endDate", value.to);
            }
            catch(e){
                console.log("UpdateocalValuesERROR", e);
            }
        }
    
    
        function updateDates(start, end){
            setValue({
                from: start,
                to: end
            });
        }
    
    useEffect(()=>{ getData(); },[value.from, value.to])
    


    function getData(){
        if (siteCode === undefined){
            console.log("No siteCode provided");
            return;
        }

        apiPost("/api/messages/move/data/getdata/", 
            {   
                siteCode: siteCode, 
                moveID: siteCode, 
                startDateTime: value.from, 
                endDateTime: value.to, 
                tagType: "13" 
            }, (d)=>{processData(d)},(e)=>{console.log(e)});
    }

    function calculateDurationSeconds(time_beg, time_end){
        try {
            return Math.round((new Date(time_end) - new Date(time_beg))/1000);
        }
        catch(e){
            console.log(e);
            return 0;
        }
    }

    function processData(mData){

        console.log("mData",mData); 
        //add duration value in seconds based on time_beg and time_end
        mData.forEach(element => { 
            if (element.tag_name === "") element.tag_name = element.tag_hwid;
            element.duration = calculateDurationSeconds(new Date(element.time_beg),new Date(element.time_end)); 
        });



        //group by zone_id
        const groupedData = mData.reduce((acc, element) => {
            const key = element.tag_hwid;
            if (!acc[key]) { acc[key] = []; }
            acc[key].push(element);
            return acc;
        }, {});

        const summaryDate = Object.keys(groupedData).map((key) => {
            const zoneData = groupedData[key];
            const tags = zoneData.map((tag) => tag.tag_name).join(", ");
            const duration = zoneData.reduce((acc, tag) => acc + tag.duration, 0);
            const time_beg = zoneData.reduce((acc, tag) => acc < tag.time_beg ? acc : tag.time_beg, zoneData[0].time_beg);
            const time_end = zoneData.reduce((acc, tag) => acc > tag.time_end ? acc : tag.time_end, zoneData[0].time_end);
            return { 
                tag_name: zoneData[0].tag_name, 
                tags: tags, 
                duration: duration, 
                time_beg: time_beg,
                time_end: time_end,
                numberOfVisits: zoneData.length,
                averageTimePerVisit: duration / zoneData.length,
                records: zoneData
            };
        });
        console.log(summaryDate);
        setData(summaryDate);
    }

    useEffect(()=>{
        getData();
    },[siteCode]);

    updateLocalValues();


    return(
        <div 
        //className='grid-30-70-container'
        >
                        <AQDateRangePicker start={value.from} end={value.to} onChange={updateDates} />
        <br />

        <MoveCarerTable
            data={data}
            columns={columns}
            settingsType={"Move"}
            onUpdateRefreshPath={""}
        />
            
        </div>        
    );

}


 export const columns = [

    /*
    columnHelper.accessor('id', { header: 'id', size: 40 }),
    columnHelper.accessor('site_id', { header: 'site_id', size: 40 }),
    columnHelper.accessor('tag_hwid', { header: 'tag_hwid', size: 40 }),
    columnHelper.accessor('tag_name', { header: 'tag_name', size: 40 }),
    columnHelper.accessor('tag_type', { header: 'tag_type', size: 40 }),
    columnHelper.accessor('zone_id', { header: 'zone_id', size: 40 }),
    columnHelper.accessor('time_beg', { header: 'time_beg', size: 40 }),
    columnHelper.accessor('time_end', { header: 'time_end', size: 40 }),
    */
    columnHelper.accessor('tag_name', { header: 'Carer', size: 40 }),
    columnHelper.accessor('duration', { header: 'Total Duration', size: 40,
        Cell: ({ renderedCellValue, row }) => (
            <div>{secondsToHHMMSS(renderedCellValue)}</div>
        ),
    }),
    columnHelper.accessor('numberOfVisits', { header: 'Number of Visits', size: 40,
        Cell: ({ renderedCellValue, row }) => (
            <div>{renderedCellValue}</div>
        ),
    }),
    columnHelper.accessor('averageTimePerVisit', { header: 'Average time per visit', size: 400,
        Cell: ({ renderedCellValue, row }) => (
            <div>{secondsToHHMMSS(renderedCellValue)}</div>
        ),
    }),

    
/*
    columnHelper.accessor('fullDate', {
        header: 'Last reported',
        size: 40,
        Cell: ({ renderedCellValue, row }) => (
            <BatteryTimeline batteryData={[]} heartbeats={row.original.heartbeats} />
        ),
  
      }),
  */
  ];

export default MoveCarerData;